<template>
  <b-container class="main-page">
    <b-row>
      <b-col cols="4" >
        <div style="display: inline-block">
          <div>
            <b-form-checkbox
              id="checkbox-1"
              v-model="previewMode"
             
            >
              Preview
            </b-form-checkbox>

          </div>
        </div>
      </b-col>

      <b-col />

      <div
        cols="8"
        style="margin-bottom: 30px"
        class="mr-1"
      >
        <b-row h-aligh="end">
          <b-button
            v-if="!formForEditingName"
            size="sm"
            variant="primary"
            class="btn btn-primary pull-right spacer-left mx-1"
            @click="exportData"
          >
            EXPORT
          </b-button>

          <b-button
            v-if="formForEditingName"
            size="sm"
            class="mr-1"
            variant="secondary"
            @click="update()"
          >
            UPDATE
          </b-button>
          <b-button
            v-if="previewMode"
            size="sm"
            variant="outline-secondary"
            @click="previewForm"
          >
            DESIGN
          </b-button>

          <b-button
            v-if="!previewMode"
            size="sm"
            variant="outline-secondary"
            @click="previewForm"
          >
            PREVIEW
          </b-button>
        </b-row>

        <div>
          <b-button
            v-if="false"
            v-b-toggle.sidebar-1
          >
            Search Control
          </b-button>
          <b-sidebar
            id="sidebar-1"
            title="Sidebar"
            shadow
          >
            <div class="px-3 py-2 pull-right spacer-right">
              <b-row>
                <b-form-group>
                  <b-form-input
                    v-model="searchQuery"
                    placeholder="Search dictionary"
                  />
                </b-form-group>
              </b-row>

              <b-row>
                <ul>
                  <li
                    v-for="conceptItem in conceptList"
                    :key="conceptItem.name"
                  >
                    {{ conceptItem[0] }}
                  </li>
                </ul>
              </b-row>
            </div>
          </b-sidebar>
        </div>
      </div>

      <b-col
        v-show="!previewMode"
        cols="12"
      >
        <FormBuilder
          v-model="configuration"
          class="fb-area"
        />
      </b-col>

      <b-col
        v-show="previewMode"
        cols="12"
        class="top-margin"
      >
        <b-card
          no-body
          class="render-form"
        >
          <FormRenderer
            v-model="formInputData"
            :form-configuration="formData"
          />
        </b-card>
      </b-col>

      <b-modal
        ref="save_modal"
        hide-footer
      >
        <template #modal-title>
          Create Form
        </template>
        <div>
          <b-form-group
            id="fieldset-1"
            label="Enter Form title "
          >
            <b-form-input
              id="input-1"
              v-model="title"
              :state="state"
              trim
            />
          </b-form-group>
          <b-form-group
            id="fieldset-1"
            label="Enter Department"
          >
            <b-form-input
              id="input-1"
              v-model="department"
              :state="state"
              trim
            />
          </b-form-group>
        </div>
        <b-button
          class="mt-6 btn btn-primary"
          block
          @click="save()"
        >
          Save
        </b-button>

      </b-modal>
    </b-row>
  </b-container>
</template>
<script>
import {
  saveFormConfiguration,
  saveFormDoctype,
  searchConcept,
  getFormConfiguration,
  updateFormData,
  updateFormConfiguration,
} from './forms.js'

export default {
  name: 'Builder',
  components: {},

  data() {
    return {
      some_data: 'This is a vue demo',
      date: null,
      previewMode: false,
      title: '',
      department: '',
      formId: null,
      configuration: {},
      formData: null,
      formInputData: null,
      conceptList: [],
      searchQuery: '',
      formForEditingName: null,
    }
  },
  watch: {
    configuration(newValue, oldValue) {
      console.log('Old value ', oldValue)
      console.log('New Value ', newValue)
    },

    previewMode(newVal, oldVal) {
      if (newVal) {
        const conf = this.configuration
        this.formData = conf
      }
    },
    searchQuery(searchTearm) {
      this.searchConceptDictionary(searchTearm)
    },
  },
  created() {
    const formName = this.$router.currentRoute.params.id
    if (formName) {
      this.formForEditingName = formName
      this.getForm(formName)
    }
  },
mounted(){
    this.setTitle("Build Form")
  },
  methods: {
    getFormData() { },
    getFormInputData() { },
    exportData() {
      this.showModal()
    },
    searchConceptDictionary(searchTearm) {
      searchConcept(searchTearm).then(
        results => (this.conceptList = results),
      )
    },
    previewForm() {
      this.previewMode = !this.previewMode
    },
    showModal() {
      this.$refs.save_modal.show()
      this.makeDepartmentControl
    },
    getForm(name) {
      getFormConfiguration({ name }).then(config => {
        const formStringConfig = config.formdata
        const configObject = JSON.parse(formStringConfig)
        console.log('Config from server')
        console.log(formStringConfig)
        console.log(configObject)
        this.configuration = configObject
      })
    },
    saveForm(title, department, configuration) {
      this.hideModal()
      saveFormConfiguration({ name: title, department, configuration }).then(
        saved => {
          this.title = ''
          this.department = ''
          this.formForEditingName = saved.name
          // this.saveFormTable(title, configuration);
        },
      )
    },
    updateForm(name, configuration) {
      this.hideModal()
      const doctype = 'Form Design'
      const formData = { doctype, name, formdata: configuration }
      updateFormConfiguration({ formData }).then(saved => {
        this.show_alert('Form Update Successful', 5)
      })
    },
    saveFormTable(title, configuration) {
      const filtered = JSON.parse(configuration)
      const controls = Object.values(filtered.controls)
      const fields = controls.map(control => {
        console.log(control)
        let required = 0
        let datatype = 'Data'

        if (control.validations && control.validations.length > 0) {
          control.validations.forEach(validation => {
            if (validation.ruleType === 'required') {
              required = 1
            }
          })
        }

        if (control.type === 'text') {
          datatype = 'Text'
        }

        return {
          label: control.label,
          name: control.name,
          fieldname: control.name,
          fieldtype: datatype,
          rqd: required,
          docstatus: 0,
          doctype: 'DocField',
          owner: 'Administrator',
          non_negative: 0,
          hide_days: 0,
          hide_seconds: 0,
          search_index: 0,
          in_list_view: 1,
          in_standard_filter: 0,
          in_global_search: 0,
          in_preview: 0,
          allow_in_quick_entry: 0,
          bold: 0,
          translatable: 0,
          collapsible: 0,
          fetch_if_empty: 0,
          hidden: 0,
          read_only: 0,
          unique: 0,
          set_only_once: 0,
          allow_bulk_edit: 0,
          permlevel: 0,
          ignore_user_permissions: 0,
          allow_on_submit: 0,
          report_hide: 0,
          remember_last_selected_value: 0,
          ignore_xss_filter: 0,
          hide_border: 0,
          in_filter: 0,
          no_copy: 0,
          print_hide: 0,
          print_hide_if_no_value: 0,
          parentfield: 'fields',
          parenttype: 'DocType',
          idx: 1,
          __unedited: false,
        }
      })

      console.log(fields)

      const permissions = [
        {
          docstatus: 0,
          doctype: 'DocPerm',
          __islocal: 1,
          __unsaved: 1,
          owner: 'Administrator',
          if_owner: 0,
          permlevel: 0,
          select: 0,
          read: 1,
          write: 1,
          create: 1,
          delete: 1,
          submit: 0,
          cancel: 0,
          amend: 0,
          report: 1,
          export: 1,
          import: 0,
          set_user_permissions: 0,
          share: 1,
          print: 1,
          email: 1,
          parentfield: 'permissions',
          parenttype: 'DocType',
          idx: 1,
          role: 'System Manager',
        },
      ]
      saveFormDoctype({ name: title, form: fields, permissions }).then(
        saved => {
          this.show_alert(`Form Saved ${saved.name}`, 5)
        },
      )
    },
    hideModal() {
      this.$refs.save_modal.hide()
    },
    update() {
      if (this.formForEditingName) {
        this.updateForm(
          this.formForEditingName,
          JSON.stringify(this.configuration),
        )
      } else {
        this.show_alert('Name not found')
      }
    },
    save() {
      this.saveForm(
        this.title,
        this.department,
        JSON.stringify(this.configuration),
      )
    },
  },
}
</script>
<style scoped>
.spacer {
    margin-top: 10px;
}

.space-right {
    margin-right: 10px !important;
    padding-right: 10px;
}

.spacer-left {
    margin-left: 10px !important;
    padding-left: 10px;
}

.spacer-left-5 {
    margin-left: 5px !important;
    padding-left: 5px;
}

.render-form {
    padding-top: 20px;
    padding-right: 20px;
    padding-left: 20px;
    padding-bottom: 50px;
}

.form-border {
    border: 1px solid darkgray;

    margin-top: 20px;
    padding-bottom: 30px;
    margin-bottom: 50px;
}

.main-padding {
    padding-bottom: 50px;
    padding-right: 50px;
}

.main-page {
    margin-top: 10px;
    padding-right: 30px;
}

.top-margin {
    margin-top: 20px;
}

.card {
    /* Add shadows to create the "card" effect */
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
}

/* On mouse-over, add a deeper shadow */
.card:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.fb-area {
    padding-top: 20px;
    border-radius: 10px;
}

.container-fluid {
    background-color: white;
    border-radius: 10px;
}

.save-data {
    margin-top: 20px !important;
}
</style>
