import useJwt from '@/auth/jwt/useJwt'

export const api = ({ method, args = {}, freeze = true }) => new Promise((resolve, reject) => useJwt.api(method, args)
  .then(response => resolve(response.data))
  .catch(error => reject(error)))

//  export const api = ({ method, args = {} }) =>
//     new Promise((resolve, reject) =>
//         frappe.call({ method, type:'GET', args, callback: resolve })
//     );

export const saveFormConfiguration = ({
  name = '',
  configuration = '',
  department = '',
}) => api({
  method: 'clinical.api.forms.form_builder.save_form_configuration',
  args: {
    name,
    configuration,
    department,
  },
}).then(({ message }) => message)

export const getFormConfiguration = ({ name = '' }) => api({
  method: 'clinical.api.forms.form_builder.get_form_configuration',
  args: {
    name,
  },
}).then(({ message }) => message)

export const getDefaultForm = ({ name = '' }) => api({
  method: 'clinical.api.forms.form_builder.get_default_form',
  args: {
    name,
  },
}).then(({ message }) => message)

export const getFormsPreferences = ({ name = '' }) => api({
  method: 'clinical.api.forms.form_builder.get_saved_forms',
  args: {
    name,
  },
}).then(({ message }) => message)

export const getFormsVersions = ({ name = '' }) => api({
  method: 'clinical.api.forms.form_builder.get_form_versions',
  args: {
    name,
  },
}).then(({ message }) => message)

export const saveFormDoctype = ({ name = '', form, permissions }) => api({
  method: 'clinical.api.forms.form_builder.save_form_doctype',
  args: {
    name,
    form,
    permissions,
  },
}).then(({ message }) => message)

export const saveFormData = ({ formData }) => api({
  method: 'clinical.api.forms.form_builder.save_form_data',
  args: {
    form_data: formData,
  },
}).then(({ message }) => message)

export const updateFormData = ({ formData }) => api({
  method: 'clinical.api.forms.form_builder.update_form_data',
  args: {
    form_data: formData,
  },
}).then(({ message }) => message)

export const updateFormConfiguration = ({ formData }) => api({
  method: 'clinical.api.forms.form_builder.update_form_configuration',
  args: {
    form_data: formData,
  },
}).then(({ message }) => message)

export const searchConcept = txt => api({
  method: 'clinical.api.forms.form_builder.concept_query',
  args: {
    txt,
  },
}).then(({ message }) => message)

export const updateDoctypeData = ({ formData }) => api({
  method: 'clinical.api.forms.form_builder.update_doctype_data',
  args: {
    form_data: formData,
  },
}).then(({ message }) => message)
